


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Header */

.header {
  height: 50px;
  background-color: rgb(24, 96, 163);
  padding-top: 20px;
  padding-left: 20px;
  /* position: fixed;
  top: 0px;
  margin-top: 0; */
  width: 100vw;
}

.logo {
  height: 10px;
}

.headernav {
  margin-top: 20px; 
}

.headericons {
  font-size: 40px;
  color: rgb(65, 65, 65);
  text-decoration: none;
}

.headericons:hover{
  color: rgb(87, 87, 87);
}

/* HomeBody */

.align-center {
  text-align: center; 
}

.box-div {
  background-color:  rgb(65, 65, 65);
  margin-left: 30px;
  margin-right: 30px;
  padding: 10px;
  border-radius: 10px;
  color: white;
}

.profileImage {
  /* margin-top: 30px; */
  height: 300px; 
  text-align: center; 
}


.img {
  border-radius: 50%;
  border: 1px solid grey;
  border: 10px solid  rgb(65, 65, 65) ;

}

.homeBody {
  text-align: center; 
}

.imageDiv {
  padding-top: 30px;
  background-color: rgb(94, 94, 94);
}

.videoContainer{
  padding-top: 10px;
  /* margin-left: 10px; */
  padding-bottom: 30px;
  display: flex;
}

#videoOne{
  width:50%;
}

#videoTwo{
  width:50%;
}

.footer {
  height: 50px;
  background-color: rgb(24, 96, 163);
  padding-top: 20px;
  text-align: center; 
  
}





